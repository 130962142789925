//	BREAKPOINTS
$mobile: 23em; 				//	~= 375	iPhone 6/7/8
$large_mobile: 25em; 		//	~= 414	iPhone 6/7/8 Plus
$small_tablet: 48em;		//	768			Tablet
$tablet: 64em;				//	1024		Large Tablet OR Landscape Tablet
$small_desktop: 80em;		//	1280		Notebooks
$desktop: 90em;				//	1440		Large Notebooks	OR Desktops
$large_desktop: 120em;	    //	1920		Large Desktops

//  COLORS
$white: #fff;
$black: #000;
$light_gray: #efefef;
$glitter: #E5EBEF;
$another_gray: #EFF0F2;
$lavender: #c7c8c9;
$cardinal: #C9243F;
$amaranth_red: #da1a31;
$faded_red: rgba(218,26,49,0.25);
$midnight_blue: #013963;
$dark_midnight_blue: #00365f;
$cerulean_blue: #0C496F;
$another_blue: #4c728f;
$yellow: #FFEA00;
$faded_yellow: #ffffd6;
$medium_gray: #ccc;
$dark_gray: #999;

//  MISC

//	FONT WEIGHTS
$light: 300;
$regular: 400;
$medium: 500;
$semi_bold: 600;
$bold: 700;
$extra_bold: 800;
