@use "../utils/mixins";

.container {

    @include mixins.breakpoint() {

        padding: 0 16px;

    }

    &.small {

        @include mixins.breakpoint() {

            width: 580px;
            margin: 0 auto;

        }

    }

    &.auto {

        @include mixins.breakpoint() {
            width: 1024px;
            margin: 0 auto;
        }

    }

    &.narrow {

        @include mixins.breakpoint() {

            width: 840px;
            margin: 0 auto;

        }

    }

    &.wide {

        @include mixins.breakpoint() {

            width: 909px;
            padding: 0;

            margin: 0 auto;

        }

    }

    &.max {

        @include mixins.breakpoint() {

            width: 972px;
            padding: 0;
            margin: 0 auto;
        }

    }

    &.v-fill {

        @include mixins.breakpoint() {

            height: 100%;

        }

    }

    &.col {

        @include mixins.breakpoint() {

            display: flex;
            flex-direction: column;

        }

    }

}