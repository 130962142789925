@use "../utils/mixins";
@use "../utils/variables";

//  UPDATED MODAL
.modal {

    .modal__overlay {

        @include mixins.breakpoint() {

            position: fixed;

            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            z-index: 99999;

            background-color: rgba(variables.$black, 0.9);

            @include mixins.animation(fade_in 600ms both);

            overflow-y: auto;

            display: flex;
            flex-direction: row;

        }

        &.keyboard-included{
            z-index: 100!important;
        }

    }

    .modal__content {

        @include mixins.breakpoint() {

            width: auto;

            -webkit-overflow-scrolling: touch;

            outline: none;

            pointer-events: none;

            margin: auto;

            //  MAGIC TOP / BOTTOM SPACING
            border-top: solid 64px transparent;
            border-bottom: solid 64px transparent;

        }


        .modal__container {

            @include mixins.breakpoint() {

                pointer-events: initial;

                @include mixins.box-shadow(0 5px 25px 0 rgba(variables.$black, 0.5));

            }

            &.narrow {

                @include mixins.breakpoint() {

                    width: 720px;

                }

            }

        }

    }

    .modal__close {

        @include mixins.breakpoint() {

            width: 40px;
            height: 40px;

            display: block;

            position: fixed;
            top: 30px;
            right: 30px;

            background-color: transparent;
            border: none;
            color: transparent;

            background-repeat: no-repeat;
            background-attachment: scroll;
            background-position: center center;
            background-size: contain;

            background-image: url('../img/close.svg');

            cursor: pointer;

            pointer-events: initial;

        }

    }

}

//  ALERT MODAL
.alert-modal {

    @include mixins.breakpoint() {

        width: 600px;
        min-height: 220px;

        background-color: variables.$white;
        border-radius: 5px;

        box-sizing: border-box;

        padding: 64px 32px;

        display: flex;
        flex-direction: column;

        pointer-events: initial;

    }

    .alert-modal_icon {

        @include mixins.breakpoint() {

            background-repeat: no-repeat;
            background-attachment: scroll;
            background-position: center center;
            background-size: cover;

            align-self: center;

        }

        &.alert {

            @include mixins.breakpoint() {

                width: 58px;
                height: 58px;

                background-image: url('../img/alert.svg');

                margin: 0 0 32px 0;

            }

        }

        &.check {

            @include mixins.breakpoint() {

                width: 80px;
                height: 80px;

                background-image: url('../img/check.svg');

                margin: 0 0 32px 0;

            }

        }

    }

    .alert-modal_message {

        @include mixins.breakpoint() {

            font-family: 'National Book',sans-serif;

            font-size: 34px;
            line-height: 1.3;
            font-feature-settings: "tnum";

            text-align: center;

            margin: auto 0 32px 0;

        }
        &.no-margin{
            margin:0;
        }

        .separator {
            width:100%;
            padding:20px;
            border-bottom: 1px solid variables.$light_gray;
        }

        .separator:last-child {
            border: none;
        }
    }

    span.title {
        color: variables.$cerulean_blue;
        display: block;
        margin-bottom: 16px;
    }

    .alert-modal_actions {

        @include mixins.breakpoint() {

            display: flex;
            flex-direction: row;
            justify-content: center;

            margin: auto 0 0 0;

        }

        .btn {

            @include mixins.breakpoint() {
                margin: 0 16px;
            }

            &:first-of-type {
                @include mixins.breakpoint() {
                    margin: 0 16px 0 0;
                }
            }

            &:last-of-type {
                @include mixins.breakpoint() {
                    margin: 0 0 0 16px;
                }
            }

            &:only-of-type {
                @include mixins.breakpoint() {
                    margin: 0;
                }
            }

        }

    }

}

//  FORM MODAL
.form-modal {

    @include mixins.breakpoint() {

        height: auto;
        max-height: calc(100vh - 200px);

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        pointer-events: initial;

    }

    &.full {

        @include mixins.breakpoint() {
            width: calc(100vw - 200px);
        }

    }

    .loading {

        @include mixins.breakpoint() {

            width: 120px;
            height: 40px;

            background-repeat: no-repeat;
            background-attachment: scroll;
            background-position: center center;
            background-size: contain;

            background-image: url('../img/loader.svg');

        }

    }

    label {
        @include mixins.breakpoint() {
            color: variables.$white;
        }
    }

    .selection {

        .selection_head {

            @include mixins.breakpoint() {
                margin: -16px 0 0 0;
            }

            .selection_row {

                .selection_cell {

                    @include mixins.breakpoint() {
                        color: variables.$white;
                    }

                }

            }

        }

    }

}


//  CONTENT MODAL
.content-modal {

    @include mixins.breakpoint() {

        width: calc(100vw - 200px);
        max-width: 824px;
        height: auto;

        // max-height: calc(100vh - 200px);

        display: flex;
        flex-direction: column;
        overflow: auto;
        pointer-events: initial;

        color: variables.$black;
        background-color: variables.$white;
        border-radius: 5px;

        box-sizing: border-box;

        padding: 32px;

    }

    h3 {
        @include mixins.breakpoint() {

            color: variables.$black;

            font-size: 32px;
            line-height: 32px;

            margin: 0 0 32px 0;

        }
    }

    p {

        @include mixins.breakpoint() {

            color: variables.$black;

            font-size: 24px;
            line-height: 1.25;

            margin: 0 0 32px 0;

        }

        &.centered {

            @include mixins.breakpoint() {
                text-align: center;
            }

        }

        &.large {

            @include mixins.breakpoint() {
                font-size: 24px;
            }

        }

    }

    b {
        font-weight: variables.$bold;
    }

    a {

        @include mixins.breakpoint() {

            color: variables.$dark_midnight_blue;

            @include mixins.transition(all 300ms ease-in-out 0ms);

        }

        &:hover {

            @include mixins.breakpoint() {

                color: variables.$black;

            }

        }

    }

    table {

        @include mixins.breakpoint() {
            font-feature-settings: 'tnum';
        }

        thead,
        tbody {

            tr {

                th,
                td {

                    @include mixins.breakpoint() {
                        width: 50%;
                        text-align: left;
                    }

                }

            }

        }

        thead {

            tr {

                th {

                    @include mixins.breakpoint() {

                        font-size: 24px;
                        line-height: 1;

                        padding: 16px 0;

                        text-transform: uppercase;

                    }

                }

            }

        }

        tbody {

            tr {

                td {

                    @include mixins.breakpoint() {

                        font-size: 24px;
                        line-height: 24px;

                        padding: 8px 0;

                    }

                }

            }

        }

    }

    &.normalized {

        p {

            @include mixins.breakpoint() {

                color: variables.$black;

                font-size: 18px;
                line-height: 1.25;

                margin: 0 0 24px 0;

            }

            &.large {

                @include mixins.breakpoint() {
                    font-size: 24px;
                }

            }

        }

    }

}
html.enable-scroll {
    overflow-y: auto!important;
}
