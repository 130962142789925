@use "../utils/mixins";

.lookup {

    @include mixins.breakpoint() {

        padding: 32px 0;

    }

}