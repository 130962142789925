@use "../utils/mixins";
@use "../utils/variables";

.esc-score_container {

    .esc-score {

        @include mixins.breakpoint() {

            display: flex;
            justify-content: center;
            align-items: center;
            color: variables.$another_blue;

            font-size: 20px;
            font-weight: variables.$regular;

            padding: 0 0 24px 0;

            position: relative;

        }

        .value {
            margin-left: 8px;
            color: variables.$dark_midnight_blue;
            font-size: 36px;
            font-feature-settings: "tnum";
        }

        .info-modal-trigger {

            @include mixins.breakpoint() {

                color: variables.$dark_midnight_blue;

                font-size: 12px;

                width: 18px;
                height: 18px;

                font-weight: variables.$bold;

                border-radius: 50%;
                border: solid 2px variables.$dark_midnight_blue;

                background-color: transparent;

                display: block;

                position: absolute;

                padding: 0!important;

                right: -20px;
                top: -18px;

                cursor: pointer;

            }

        }

    }

    &.center{
        margin:auto;
    }

}
