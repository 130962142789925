@use "../utils/mixins";

//  CUSTOM SCROLLBARS FOR TABLES
.data-table_container {

    &.grow {

        .custom_scrollbars {

            @include mixins.breakpoint() {

                flex-grow: 1;

                display: flex;
                flex-direction: column;

            }

            div {

                &:first-child {

                    @include mixins.breakpoint() {
                        flex-grow: 1;
                    }

                }

            }

        }

    }

}