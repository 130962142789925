@use "../utils/mixins";
@use "../utils/variables";

.react-select-container {
    position:relative;
    z-index:5;
    .react-select__control {

        @include mixins.breakpoint() {
            height: 52px;
            border: none;
            @include mixins.box-shadow(none);

            cursor: pointer;
        }

        .react-select__value-container {

            @include mixins.breakpoint() {
                height: 52px;
            }

            .react-select__placeholder {

                @include mixins.breakpoint() {
                    font-size: 18px;
                }

            }

            .react-select__single-value {
                @include mixins.breakpoint() {
                    font-size: 18px;
                }
            }

        }

        .react-select__indicators {

            .react-select__indicator-separator {

                @include mixins.breakpoint() {
                    display: none;
                }

            }

            .react-select__indicator {

                &.react-select__dropdown-indicator {

                    @include mixins.breakpoint() {
                        padding: 16px;
                    }

                    &::before {

                        @include mixins.breakpoint() {

                            content: '';
                            display: block;
    
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 8px 8px 0 8px;
                            border-color: variables.$dark_midnight_blue transparent transparent transparent;    

                        }

                    }

                    svg {

                        @include mixins.breakpoint() {
                            display: none;
                        }

                    }

                }

            }

        }

    }

}

.react-select-container-more {
    .react-select__option {
        &--is-selected {
            background-color: variables.$dark_midnight_blue;
            color: white;
        }
        
        & {
            color: variables.$dark_midnight_blue;
        }
    }

    .react-select__single-value {
        color: variables.$dark_midnight_blue;
    }
}

.bottom-margin {
    @include mixins.breakpoint() {
      margin-bottom: 30px;
      border: none;
    }
  }
