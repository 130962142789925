@use "../utils/mixins";

.guest {

    @include mixins.breakpoint() {

        padding-top: 5vh;

    }

}