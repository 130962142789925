@use "mixins";

@include mixins.keyframes(fade_in) {

    0% {
        opacity: 0;
        visibility: hidden;
    }
    100% {
        opacity: 1;
        visibility: visible;
    }

}

@include mixins.keyframes(fade_in_up) {

    0% {
        opacity: 0;
        @include mixins.transform(translate3d(0,50%,0));
    }
    100% {
        opacity: 1;
        @include mixins.transform(translate3d(0,0,0));
    }

}