@use "../utils/mixins";

section {

    &.golfer-lookup {

        @include mixins.breakpoint() {
    
            flex-grow: 1;

            display: flex;
            flex-direction: column;

        }
    
    }

}
