@use "../utils/mixins";
@use "../utils/variables";

.tabs-container {

    .tabs {

        @include mixins.breakpoint() {

            display: flex;
            flex-direction: row;
            justify-content: center;
            position: relative;
            z-index: 5;

        }

        li {

            @include mixins.breakpoint() {

                margin: 0 16px;

            }

            a, button {

                @include mixins.breakpoint() {

                    color: variables.$dark_midnight_blue;

                    font-size: 22px;
                    font-weight: variables.$medium;

                    text-decoration: none;
                    text-transform: uppercase;

                    display: block;

                    padding: 8px 0;

                    cursor: pointer;

                }

                &.is-active {

                    @include mixins.breakpoint() {

                        color: variables.$amaranth_red;

                        position: relative;

                    }

                    &::before {

                        @include mixins.breakpoint() {

                            content: '';
                            display: block;

                            background-color: variables.$amaranth_red;

                            height: 3px;
                            width: 100%;

                            position: absolute;
                            bottom: 0;

                        }

                    }

                }

            }

        }

    }

}