@use "../utils/mixins";
@use "../utils/variables";

.hbh-container {

    @include mixins.breakpoint() {
        margin: 24px 0 0 0;
    }

    &.score-results{
        margin: 0!important;
    }

    .hbh-pre_head {

        @include mixins.breakpoint() {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .hbh-pre_cell {

            @include mixins.breakpoint() {

                height: 60px;

                display: flex;
                flex-direction: column;
                justify-content: flex-end;

            }

            &:first-child {
                @include mixins.breakpoint() {
                    text-align: left;
                }
            }
            &:last-child {
                @include mixins.breakpoint() {
                    text-align: right;
                }
            }

            .hbh-title {

                @include mixins.breakpoint() {

                    color: variables.$dark_midnight_blue;

                    font-weight: variables.$bold;

                    font-size: 22px;

                    text-transform: uppercase;

                    font-feature-settings: "tnum";

                    margin: 0 0 10px 0;

                    display: block;

                }

            }

            .hbh-clear {

                @include mixins.breakpoint() {

                    color: variables.$cerulean_blue;

                    font-size: 26px;

                    display: block;

                    margin: 0 0 10px 0;

                    cursor: pointer;

                }

            }

        }

    }

    .hbh {

        .hbh_row {

            @include mixins.breakpoint() {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
            }

            &.hole {

                @include mixins.breakpoint() {
                    color: variables.$white;
                    background-color: variables.$dark_midnight_blue;
                }

            }

            &.yards {

                @include mixins.breakpoint() {
                    background-color: variables.$another_gray;
                }

            }

            &.par,
            &.esc_score {

                @include mixins.breakpoint() {
                    background-color: rgba(variables.$dark_midnight_blue, 0.1);
                }
            }
            &.adjusted-score, &.stroke{
                .hbh_cell{
                    padding-top: 5px;
                }
            }

            &.score {

                @include mixins.breakpoint() {
                    background-color: variables.$white;
                }

            }

            .hbh_cell {

                @include mixins.breakpoint() {

                    flex: 1;

                    text-align: center;

                    font-size: 24px;
                    line-height: 60px;

                    font-feature-settings: "tnum";
                
                    border-left: solid 1px rgba(variables.$dark_midnight_blue, 0.25);

                }

                &:first-child {
                    @include mixins.breakpoint() {
                        border-left: none;
                    }
                }

                &.label {

                    @include mixins.breakpoint() {

                        flex: none;

                        width: 160px;

                        text-align: left;

                        padding: 0 0 0 20px;

                        &.adjusted-score, &.stroke{
                            padding: 10px 0px 10px 20px;
                            line-height: 1;
                        }

                    }

                }

            }

        }

    }

}