@use "../utils/mixins";
@use "../utils/variables";

.selection {

    @include mixins.breakpoint() {
        flex: 1;
    }

    .selection_row {

        @include mixins.breakpoint() {

            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            margin: 0 48px;

        }

        .selection_cell {

            @include mixins.breakpoint() {

                flex: 1;

                font-feature-settings: "tnum";

            }

            &.handicap-index {

                @include mixins.breakpoint() {

                    flex: none;
                    width: 200px;
                    text-align: center;

                }

            }

            &.name {

                @include mixins.breakpoint() {

                    flex: none;
                    width: 40%;

                }

            }

        }

    }

    .selection_head {

        .selection_row {


            .selection_cell {

                @include mixins.breakpoint() {

                    color: variables.$dark_midnight_blue;

                    font-family: 'National Book',sans-serif;

                    font-size: 16px;

                    margin: 0 0 8px 0;

                    text-transform: uppercase;

                }

            }

        }

    }

    .selection_body {

        @include mixins.breakpoint() {

            background-color: variables.$white;
            border-radius: 3px;
            flex-grow: 1;


        }

        .selection_row {

            @include mixins.breakpoint() {

                padding-top: 20px;
                padding-bottom: 20px;

                border-bottom: solid 1px variables.$glitter;

                cursor: pointer;

            }

            &:last-of-type {

                @include mixins.breakpoint() {
                    border-bottom: none;
                }

            }

            .selection_cell {

                @include mixins.breakpoint() {

                    color: variables.$black;

                    font-size: 26px;
                    line-height: 50px;
                    font-weight: variables.$light;

                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                }

                &.text-aligh-right {
                    @include mixins.breakpoint() {
                        text-align: right;
                    }
                }

                .course-name {
                    @include mixins.breakpoint() {
                        font-size: 26px;
                        line-height: 30px;
                    }
                }
                .course-location {
                    @include mixins.breakpoint() {

                        font-size: 18px;
                        line-height: 20px;
                        font-weight: variables.$semi-bold;

                    }
                }

            }

        }

        //  SCROLLING
        .track-vertical {

            @include mixins.breakpoint() {
                position: absolute;
                top: 0;
                right: 0;
                height: calc(100% - 16px);
                margin: 8px 8px 8px 0;

            }

            .thumb-vertical {

                @include mixins.breakpoint() {

                    width: 6px;
                    border-radius: 3px;

                    background-color: variables.$lavender;

                }

            }

        }

        &.is-error {
            padding: 16px;
        }

    }

    //  BLANK
    &.blank {

        @include mixins.breakpoint() {

            color: variables.$dark_midnight_blue;

            font-size: 26px;

            align-self: center;
            margin: auto 0;

            @include mixins.transform(translate3d(0,-27px,0))

        }

    }

    //  SHARED
    .selection_head,
    .selection_body {

        .selection_row {

            .selection_cell {

                &.fixed {

                    @include mixins.breakpoint() {

                        flex: none;
                        width: 120px;

                    }

                }

            }

        }

    }

}
