@use "../utils/mixins";
@use "../utils/variables";

.toggle-radio_container {

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;

    }

    input[type="radio"] {

        @include mixins.breakpoint() {
            display: none;
        }

        &  + label {

            @include mixins.breakpoint() {

                flex: 1;

                white-space: nowrap;

                color: variables.$dark_midnight_blue;
                background-color: variables.$white;

                font-size: 18px;
                line-height: 32px;

                min-width: 140px;
                margin: 0;

                font-feature-settings: "tnum";

                text-align: center;

                @include mixins.box-shadow(inset 0 0 0 2px variables.$dark_midnight_blue);

                will-change: color;

                @include mixins.transition(all 300ms ease-in-out 0ms);

                cursor: pointer;

            }

        }

        &:disabled {
            & + label {

                @include mixins.breakpoint() {

                    color: variables.$dark_gray;
                    @include mixins.box-shadow(inset 0 0 0 2px variables.$dark_gray);
                    pointer-events: none;
                }

            }
        }

        &:checked {

            & + label {

                @include mixins.breakpoint() {

                    color: variables.$white;
                    background-color: variables.$dark_midnight_blue;

                }

            }

        }

    }

}

//  BOX RADIO
.box-radio_container {

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: row;
        justify-content: center;

    }

    input[type="radio"] {

        @include mixins.breakpoint() {
            display: none;
        }

        & + label {

            @include mixins.breakpoint() {

                font-size: 32px;
                font-weight: variables.$medium;

                color: variables.$dark_midnight_blue;
                background-color: variables.$white;

                width: 200px;
                height: 200px;

                border-radius: 5px;

                flex: none;

                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                margin: 0 16px;

                font-feature-settings: "tnum";

                @include mixins.transition(all 300ms ease-in-out 0ms);

            }

        }

        &:checked {

            & + label {

                @include mixins.breakpoint() {

                    color: variables.$white;
                    background-color: variables.$dark_midnight_blue;

                }

            }

        }

        &:disabled {
            & + label {

                @include mixins.breakpoint() {

                    color: #b8b8b8;
                    background-color: #999999;
                    cursor: not-allowed;
                }

            }
        }

    }

}

.default-rardio-container {

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: row;
        justify-content: center;

    }

}

.default-radio {

    position:relative;
    z-index:5;

    @include mixins.breakpoint() {
        margin: 0 8px;
    }

    input[type="radio"] {

        @include mixins.breakpoint() {
            display: none;
        }

        & + label {

            @include mixins.breakpoint() {

                color: variables.$dark_midnight_blue;

                font-size: 18px;
                line-height: 32px;
                font-weight: variables.$regular;

                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;

                margin: 0;

            }

            &::before {

                @include mixins.breakpoint() {

                    content: '';
                    display: block;

                    width: 22px;
                    height: 22px;

                    border: solid 2px variables.$dark_midnight_blue;
                    border-radius: 50%;

                    margin: 0 8px 0 0;

                }

            }

        }

        &:checked {

            & + label {

                &::before {

                    @include mixins.breakpoint() {

                        background-color: variables.$dark_midnight_blue;
                        @include mixins.box-shadow(inset 0 0 0 4px variables.$light_gray);

                    }

                }

            }

        }

    }

}
