@use "../utils/mixins";
@use "../utils/variables";

.f-row {

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: row;
        
        margin: 0 0 32px 0;

        width: 100%;

    }

    &.j-center {

        @include mixins.breakpoint() {

            justify-content: center;

        }

    }

}

.f-col {

    @include mixins.breakpoint() {

        flex: 1;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        padding: 0 16px 0;

    }

    &.reduced-padding {

        @include mixins.breakpoint() {
            padding: 0 8px;
        }

    }

    &:first-of-type {
        @include mixins.breakpoint() {
            padding-left: 0;
        }
    }
    &:last-of-type {
        @include mixins.breakpoint() {
            padding-right: 0;
        }
    }

    //  SIZES

    &.is-full {
        @include mixins.breakpoint() {
            flex: none;
            width: 100%;
            padding: 0;
        }
    }

    &.centered {
        @include mixins.breakpoint() {
            margin: 0 auto;
            z-index:4;
        }
    }

    &.is-1-of-2 {

        @include mixins.breakpoint() {

            flex: none;
            width: 50%;

        }

    }

    &.is-1-of-3 {

        @include mixins.breakpoint() {

            flex: none;

            width: 33.333333%;

        }

        &:first-of-type {
            @include mixins.breakpoint() {
                margin: 0;
            }
        }
        &:last-of-type {
            @include mixins.breakpoint() {
                margin: 0;
            }
        }
        &.centered {
            @include mixins.breakpoint() {
                margin: 0 auto;
            }
        }

    }

    &.is-2-of-3 {

        @include mixins.breakpoint() {

            flex: none;
            width: 66.666666%;

        }

    }
    &.is-auto {
        flex: none;
        width: auto;
    }

}

label {

    @include mixins.breakpoint() {

        color: variables.$dark_midnight_blue;

        font-size: 20px;
        font-weight: variables.$medium;

        margin: 0 0 10px 0;

        font-feature-settings: "tnum";

    }

}