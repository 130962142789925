@use "mixins";
@use "variables";

//	FONT DECLARATION
body {
	font-family: 'National', sans-serif;
}

//	FONT SMOOTHING
html {
	-webkit-font-smoothing: antialiased;
}

//	BORDERBOX SETUP
* {
	box-sizing: border-box;
}

//	GENERAL BACKGROUND
body {
	background-color: variables.$light_gray;
}

p {
	&.center {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		font-size: 28px;
		color: variables.$dark_midnight_blue;
	}
}
input {
	position:relative;
	z-index:5;
}

//	FIXED HEIGHT FOR ROOT
#root {

	height: 100vh;

	display: flex;
	flex-direction: column;

}

//	PREVENT SCROLL
html {

	&.prevent-scroll {
		@include mixins.breakpoint() {
			overflow: hidden;
		}
	}

}