.keyboard-exit {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 0;
}

.simple-keyboard {

    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #fff !important;
    padding: 20px !important;
    font-family: 'National', sans-serif !important;
    font-feature-settings: "tnum";
    opacity: 1;
    z-index: 999;

    &.hidden {
        opacity: 0;
        pointer-events: none;
    }

    &.hg-layout-number {
        font-feature-settings: "tnum";
    }

    .hg-row {
        display: flex;
        align-items: center !important;
        justify-content: center !important;
        //need help
        &:not(:last-child) {
            margin-bottom: 20px !important;
        }
    }
    .hg-button {
        cursor: pointer;
        box-shadow: none !important;
        border: 1px solid #C8C9CA !important;
        border-radius: 3px !important;
        height: 8vh !important;
        background-color: #F6F7F7 !important;
        color: #34393E !important;
        span {
            font-size: 24px !important;
        }
        &.hg-standardBtn[data-skbtn="@"] {
            max-width: inherit !important;
        }
        &.hg-button-abc, &.hg-button-special {
            padding: 5px 20px;
        }
        &:not(:last-child) {
            margin-right: 20px !important;
        }
        &.active {
            background-color: #C8C9CA !important;
        }
        &.shift {
            background-image: url('../img/shift.svg');
            background-size: auto 50%;
            background-position: center;
            background-repeat: no-repeat;
        }
        &.hide {
            width: 5%;
            background-image: url('../img/hide.svg');
            background-size: auto 50%;
            background-position: center;
            background-repeat: no-repeat;
        }
        &.space {
            width: 60%;
        }
        &.backspace {
            background-image: url('../img/backspace.svg');
            background-size: auto 50%;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}

//  UPDATES
body.keyboard--is--open {
    #root {
        margin-bottom: 20vh;
    }
}
body.keyboard--is--open--number {
    #root {
        margin-bottom: 100px;
    }
}
