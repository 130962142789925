@use "../utils/mixins";
@use "../utils/variables";

.data-table-pre_container {

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: column;

        overflow: hidden;

    }

}

.data-table_container {

    @include mixins.breakpoint() {

        padding: 20px;
        margin: 20px 0 20px 0;

        background-color: variables.$white;

        display: flex;
        flex-direction: column;

    }

    &.grow {

        @include mixins.breakpoint() {
            flex-grow: 1;
        }

    }

    &.score-results {
        margin-top: 0;
    }

    .data-table_pre {

        @include mixins.breakpoint() {

            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;

            flex-shrink: 0;

        }

        .pre-left {
            @include mixins.breakpoint() {
                flex: 1;

                display: flex;
                flex-direction: column;
                justify-content: flex-start;
            }
        }

        .pre-right {
            @include mixins.breakpoint() {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
            }
        }

        .pre-title {

            @include mixins.breakpoint() {

                color: variables.$dark_midnight_blue;

                font-size: 24px;
                line-height: 24px;

                display: flex;
                flex-direction: row;

            }

            &.icon {

                &::after {

                    @include mixins.breakpoint() {

                        content: '';
                        display: block;

                        background-repeat: no-repeat;
                        background-attachment: scroll;
                        background-position: center center;
                        background-size: contain;

                        width: 24px;
                        height: 24px;

                        margin: 0 0 0 8px;

                    }

                }

            }

            &.search {

                &::after {

                    @include mixins.breakpoint() {

                        background-image: url('../img/field-search.svg');

                    }

                }

            }

        }

    }

    //  DATA-TABLE GENERAL STYLES
    .table_head {

        @include mixins.breakpoint() {

            display: flex;
            flex-direction: column;
            flex-shrink: 0;

            width: 100%;

        }

        .table_row {

            @include mixins.breakpoint() {

                display: flex;
                flex-direction: row;

                font-size: 18px;
                line-height: 48px;

            }

            .table_cell {

                @include mixins.breakpoint() {

                    color: variables.$dark_midnight_blue;
                    position:relative;
                    text-transform: uppercase;

                }
                .info-modal-trigger {

                    @include mixins.breakpoint() {

                        color: variables.$dark_midnight_blue;

                        font-size: 12px;

                        width: 18px;
                        height: 18px;

                        font-weight: variables.$bold;

                        border-radius: 50%;
                        border: solid 2px variables.$dark_midnight_blue;

                        background-color: transparent;

                        display: block;

                        position: absolute;

                        padding: 0!important;

                        right: 25px;
                        top: 5px;

                        cursor: pointer;

                    }

                }

                &:not(:first-child) {
                    @include mixins.breakpoint() {
                        text-align: center;
                    }
                }

            }

        }

    }

    .data-table {

        @include mixins.breakpoint() {

            width: 100%;

        }

        .table_row {

            @include mixins.breakpoint() {

                display: flex;
                flex-direction: row;

                color: variables.$dark_midnight_blue;

                font-size: 18px;
                line-height: 20px;

                border-top: solid 1px rgba(variables.$glitter, 0.75);

            }

            &:nth-child(odd) {
                @include mixins.breakpoint() {
                    background-color: rgba(variables.$glitter, 0.5);
                }
            }

            &:last-of-type {
                @include mixins.breakpoint() {
                    border-bottom: solid 1px rgba(variables.$glitter, 0.75);
                }
            }

            .table_cell {

                @include mixins.breakpoint() {
                    padding-top: 4px;
                    padding-bottom: 4px;

                    min-height: 40px;
                    box-sizing: content-box;

                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    font-feature-settings: "tnum";
                }

                .info-modal-trigger {

                    @include mixins.breakpoint() {

                        color: variables.$dark_midnight_blue;

                        font-size: 12px;

                        width: 18px;
                        height: 18px;
                        margin-left: 10px;
                        font-weight: variables.$bold;

                        border-radius: 50%;
                        border: solid 2px variables.$dark_midnight_blue;

                        background-color: transparent;

                        display: inline-block;

                        padding: 0!important;

                        cursor: pointer;

                    }

                }
                &.esc-score {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .info-modal-trigger {
                        @include mixins.breakpoint() {
                            position: initial;
                        }
                    }
                }
                &:first-child {
                    @include mixins.breakpoint() {
                        padding: 0 0 0 10px;
                    }
                }

                &:not(:first-child) {
                    @include mixins.breakpoint() {
                        text-align: center;
                    }
                }

            }

        }

    }

    //  COURSE HANDICAP SPECIFIC WIDTHS
    .table_head.course-handicap,
    table.course-handicap,
    table.course-handicap tbody {

        .table_row,
        tr {

            .table_cell,
            td {

                &.tees {
                    @include mixins.breakpoint() {
                        width: 50%;
                    }
                }

                &.rating-slope {
                    @include mixins.breakpoint() {
                        width: 25%;
                    }
                }

                &.course-handicap {
                    @include mixins.breakpoint() {
                        width: 25%;
                    }
                }

                &.trend {
                    @include mixins.breakpoint() {
                        width: 25%;
                    }
                }

            }
        }
    }

    //  MOST RECENT SCORES
    .table_head.most-recent-scores,
    table.most-recent-scores,
    table.most-recent-scores tbody {

        .table_row,
        tr {

            .table_cell,
            td {

                &.star {
                    @include mixins.breakpoint() {
                        width: 7.5%;
                    }
                }
                &.type {
                    @include mixins.breakpoint() {
                        width: 7.5%;
                    }
                }
                &.date {
                    @include mixins.breakpoint() {
                        width: 15%;
                    }
                }
                &.esc-score {
                    @include mixins.breakpoint() {
                        width: 12.5%;
                    }
                }
                &.rating-slope {
                    @include mixins.breakpoint() {
                        width: 20%;
                    }
                }
                &.pcc {
                    @include mixins.breakpoint() {
                        width: 15%;
                    }
                }
                &.diff {
                    @include mixins.breakpoint() {
                        width: 15%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: flex-start;
                    }
                }
                &.course {
                    @include mixins.breakpoint() {
                        width: 25%;
                        text-align: left;
                    }
                }

            }
            &.title {
                padding: 16px 8px;
                justify-content: center;
                text-transform: uppercase;
                background: #fff;
                font-size: 24px;
            }
            &.italic{
                font-style: italic;
            }

            .subscript{
                font-size: .6em;
                vertical-align: sub;
            }
        }
    }

    //  ESC COLOR
    table.most-recent-scores tbody {
        tr {
            td {
                &.esc-score {
                    &.faded-yellow {
                        @include mixins.breakpoint() {
                            background-color: variables.$faded_yellow;
                        }
                    }
                    &.nine-holes {
                        @include mixins.breakpoint() {
                            background-color: variables.$faded_red
                        }
                    }
                }
            }
        }
    }

    //  HANDICAP HISTORY
    .table_head.handicap-index,
    table.handicap-index tbody {

        .table_row,
        tr {

            .table_cell,
            td {

                &.revision {
                    @include mixins.breakpoint() {
                        width: 20%;
                    }
                }
                &.handicap-index {
                    @include mixins.breakpoint() {
                        width: 60%;
                    }
                }

            }
        }
    }


    //  COMMON OVERRIDES
    .table_head,
    .data-table tbody {

        .table_row,
        tr {

            .table_cell,
            td {

                @include mixins.breakpoint() {

                    padding: 0;

                    text-align: center;

                }

                &:first-child {

                    @include mixins.breakpoint() {

                        padding: 0;

                    }

                }

                &:last-child {

                    @include mixins.breakpoint() {

                        text-align: left;

                    }

                }

            }

        }

    }

    //  COURSE HANDICAP OVERRIDES
    //  HANDICAP HISTORY OVERRIDES

    .handicap-index,
    .course-handicap {

        &.table_head,
        &.data-table tbody {

            .table_row,
            tr {

                @include mixins.breakpoint() {
                    padding: 0 0 0 16px;
                }

                .table_cell,
                td {

                    @include mixins.breakpoint() {

                        text-align: center;
                    }



                    &:first-child {

                        @include mixins.breakpoint() {

                             text-align: left;

                        }

                    }

                    &:last-child {

                        @include mixins.breakpoint() {

                            text-align: center;

                        }

                    }

                }

            }

        }


    }

}


.table__legend {

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 0 8px;
        font-feature-settings: "tnum";
        border: solid 1px variables.$light_gray;

    }

    li {

        @include mixins.breakpoint() {

            white-space: nowrap;

            color: variables.$dark_gray;

            font-size: 14px;
            line-height: 38px;

            margin: 0 16px 0 0;

            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;

            .material-icons-outlined {

                @include mixins.breakpoint() {
                    margin: 0 8px 0 0;
                }

            }

            .legend-item {

                @include mixins.breakpoint() {

                    display: inline-block;

                    width: 32px;
                    height: 16px;

                    border: solid 1px variables.$light_gray;

                    margin: 0 16px 0 0;

                }

            }

        }

        &:last-child {

            @include mixins.breakpoint() {
                margin: 0;
            }

        }
        span {
            font-weight: bold;
            margin: 0 3px;
        }
    }

}

//  TABLE LEGEND
.table-legend__container {

    &.bottom {
        height: 36px;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        flex-shrink: 0;

        margin: 0 0 -10px 0;

    }

    .table-legend__item {

        @include mixins.breakpoint() {

            display: flex;
            flex-direction: row;
            font-feature-settings: "tnum";

        }

        .legend__item-color {

            @include mixins.breakpoint() {

                width: 46px;
                height: 14px;

                border: solid 1px variables.$white;

                margin: 0 8px 0 0;

            }

            &.faded-yellow {
                @include mixins.breakpoint() {
                    background-color: variables.$faded_yellow;
                }
            }
            &.faded-red {
                @include mixins.breakpoint() {
                    background-color: variables.$faded_red;
                }
            }

        }
        .legend__item-value {
            color: variables.$dark_midnight_blue;
            margin-right: 8px;
        }
        .legend__item-name {

            @include mixins.breakpoint() {

                color: variables.$dark_gray;

                font-size: 14px;
                line-height: 16px;

            }

        }

    }

}
