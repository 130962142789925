@use "../utils/mixins";
@use "../utils/variables";

.post-total-score-container {

    @include mixins.breakpoint() {

        width: 100%;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        justify-content: center;
        align-items: flex-end;

        margin: 0 0 24px 0;

    }

    .score-cell {

        @include mixins.breakpoint() {

            flex: 1;

            padding: 0 8px;

        }

        &:first-of-type {
            @include mixins.breakpoint() {
                padding: 0 16px 0 0;
            }
        }
        &:last-of-type {
            @include mixins.breakpoint() {
                padding: 0 0 0 16px;
            }
        }


        label {

            @include mixins.breakpoint() {

                margin: 0 0 8px 0;
                display: block;

            }

            span {
                color: variables.$cardinal;
            }

        }

        .large-score {

            @include mixins.breakpoint() {

                color: variables.$dark_midnight_blue;

                font-size: 72px;

                font-family: 'National Book',sans-serif;
                font-feature-settings: "tnum";

                line-height: 1;

                font-weight: variables.$bold;

                text-align: center;

                margin: 0;

            }

        }

        &:only-of-type {

            @include mixins.breakpoint() {

                flex: none;
                width: 33.333333%;
                padding: 0;

            }

        }

    }

}
