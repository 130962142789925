@use "../utils/mixins";
@use "../utils/variables";

//  HEADER

.loading-container{

    @include mixins.breakpoint() {

        background-color: variables.$another_gray;

        padding: 10px 0;
        width: 100%;
        height: 100%;
        display: flex!important;
        flex-direction: column!important;
        justify-content: center!important;
        align-items: center!important;

    }

    .loading {
        margin: 20px 0 0 0 !important;
    }


    .identity {

        @include mixins.breakpoint() {

            display: flex;
            flex-direction: column;
            align-items: center;

            flex: none;

            height: 68px;

        }

        .identity_logo {

            @include mixins.breakpoint() {

                width: 115px;
                height: 32px;

                background-repeat: no-repeat;
                background-attachment: scroll;
                background-position: center center;

                background-size: cover;

                background-image: url('../img/usga_logo.svg');

                margin: 0 0 4px 0;

            }

        }

        .identity_sub {

            @include mixins.breakpoint() {

                font-size: 16px;
                font-weight: variables.$bold;
                text-align: center;

                color: variables.$cerulean_blue;

            }

        }

    }

}