@use "../utils/mixins";
@use "../utils/variables";

.tees-table {

    @include mixins.breakpoint() {

        background-color: variables.$white;
    
        width: 520px;

    }

    .tees-table_head {

        @include mixins.breakpoint() {

            background-color: variables.$another_gray;
            border-bottom: solid 1px rgba(variables.$dark_midnight_blue, 0.1);

        }

        .tees-table_row {

            @include mixins.breakpoint() {

                padding: 0 24px;

            }

            .tees-table_cell {
    
                @include mixins.breakpoint() {

                    flex: 1;

                    font-size: 24px;
                    line-height: 60px;

                }

            }
    
        }

    }

    .tees-table_body {

        .tees-table_row {

            @include mixins.breakpoint() {

                margin: 0 24px;
                border-bottom: solid 1px rgba(variables.$dark_midnight_blue, 0.1);
            
            }

            .tees-table_cell {
    
                @include mixins.breakpoint() {

                    color: variables.$dark_midnight_blue;

                    font-size: 24px;
                    line-height: 64px;
                    font-weight: variables.$medium;

                    font-feature-settings: "tnum";

                    flex: 1;

                }

            }
    
        }

    }

    .tees-table_row {

        @include mixins.breakpoint() {

            display: flex;
            flex-direction: row;

        }

        .tees-table_cell {

            @include mixins.breakpoint() {
                flex: 1;
            }

        }

    }

}