@use "../utils/mixins";
@use "../utils/variables";

//  HEADER

header {

    @include mixins.breakpoint() {

        background-color: variables.$white;

        padding: 10px 0;

        flex: none;

    }

    .control {

        @include mixins.breakpoint() {

            flex: 1;
            font-size: 32px;
            cursor: pointer;
            opacity: 0;
            pointer-events: none


        }

        &.visible{
            opacity:1;
            pointer-events: initial;
        }
    }

    .identity {

        @include mixins.breakpoint() {

            display: flex;
            flex-direction: column;
            align-items: center;

            flex: none;

            height: 68px;

        }

        .identity_logo {

            @include mixins.breakpoint() {

                width: 115px;
                height: 32px;

                background-repeat: no-repeat;
                background-attachment: scroll;
                background-position: center center;

                background-size: cover;

                background-image: url('../img/usga_logo.svg');

                margin: 0 0 4px 0;

            }

        }

        .identity_sub {

            @include mixins.breakpoint() {

                font-size: 16px;
                font-weight: variables.$bold;

                color: variables.$cerulean_blue;

            }

        }

    }

    .powered-by {

        @include mixins.breakpoint() {

            display: flex;
            flex-direction: column;

            align-items: flex-start;
            justify-content: flex-end;

            margin-left: 20px;

        }

        .powered-by_text {

            @include mixins.breakpoint() {

                color: variables.$dark_gray;

                font-family: 'National Book',sans-serif;
                font-size: 14px;
                font-weight: 800;

            }

        }

        .powered-by_logo {

            &.rolex {

                @include mixins.breakpoint() {

                    width: 80px;
                    height: 42px;

                    background-repeat: no-repeat;
                    background-attachment: scroll;
                    background-position: center center;

                    background-size: cover;

                    background-image: url('../img/rolex_logo.svg');

                }

            }
            &.sentry {
                @include mixins.breakpoint() {

                    width: 80px;
                    height: 60px;

                    background-repeat: no-repeat;
                    background-attachment: scroll;
                    background-position: center center;

                    background-size: contain;

                    background-image: url('../img/sentry_logo.png');

                }
            }

        }

    }

    //  GAP HEADER
    &.gap__header {

        @include mixins.breakpoint() {

            padding: 10px 0;

        }

        .row {
            @include mixins.breakpoint() {
                align-items: center;
            }
        }

        .identity {

            @include mixins.breakpoint() {

                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;

                margin: 0 0 0 auto;

            }

        }

        .branded-identity_container {

            @include mixins.breakpoint() {

                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;

                margin: 0 auto 0 0;

            }

            .branded-identity {

                @include mixins.breakpoint() {

                    width: 70px;
                    height: 70px;

                    background-repeat: no-repeat;
                    background-attachment: scroll;
                    background-position: center center;

                    background-size: cover;

                    margin: 0 26px 0 0;

                }

                &.gap {

                    @include mixins.breakpoint() {

                        background-image: url('../img/brands/gap_logo.jpg');

                    }

                }

            }

            .branded-identity_name {

                @include mixins.breakpoint() {

                    font-family: 'National Book',sans-serif;

                    color: variables.$dark_midnight_blue;

                    font-size: 34px;
                    font-weight: variables.$medium;

                }

            }

        }

    }

}
