.btn_container {
  display: flex;
  justify-content: center;
  align-items: center;

  .btn {
    &.lnk {
      font-size: 16px;
      line-height: 35px;
      font-weight: 500;
      background-color: transparent;
      text-decoration: underline;
      text-transform: none;
      letter-spacing: normal;
      color: #0067e6;
    }
  }
}
