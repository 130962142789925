@use "../utils/mixins";

section {

    &.top-padding {

        &.large {

            @include mixins.breakpoint() {
                padding-top: 64px;
            }

        }

        &.small {

            @include mixins.breakpoint() {
                padding-top: 32px;
            }

        }

    }

    &.grow {

        @include mixins.breakpoint() {

            flex-grow: 1;

        }

    }

    div.empty {
        height: 88px;
    }
}