@use "../utils/mixins";
@use "../utils/variables";

input[type="text"] {

    @include mixins.breakpoint() {

        font-family: 'National Book',sans-serif;

        height: 52px;

        font-size: 26px;
        line-height: 52px;

        padding: 0 20px;
        width: 100%;

        color: variables.$midnight_blue;
        background-color: variables.$white;

        border: none;
        border: 1px solid variables.$midnight_blue;
        border-radius: 3px;

        outline-style: none;

        font-feature-settings: "tnum";

        cursor: pointer;

    }


    &.hbh-score-input,
    &.score-input {

        @include mixins.breakpoint() {

            width: 100%;
            line-height: 60px;
            height: 60px;

            border-radius: 0;
            border:none;
            position: relative;
            z-index: 5;
            font-feature-settings: "tnum";
            &.total {
                font-size: 32px;
                font-weight: bold;
            }

            padding: 0;
            text-align: center;

            // font-weight: $semi-bold;

        }

        &.is-danger{
            @include mixins.box-shadow(inset 0 0 0 4px variables.$amaranth_red);
        }

        &:focus, &.focused {

            @include mixins.breakpoint() {

                //@include box-shadow(inset 0 0 0 4px $dark_midnight_blue); -- not working on ios devices
                border: 4px solid variables.$dark_midnight_blue;

            }

        }
        &:disabled {
            opacity:0.5;
        }

    }

     &.score-input {
        border-radius: 3px;
        border: 1px solid variables.$midnight_blue;
     }

    &.search {

        @include mixins.breakpoint() {

            padding: 0 20px 0 64px;

            background-repeat: no-repeat;
            background-attachment: scroll;
            background-position: 20px center;
            background-size: 24px 24px;

            background-image: url('../img/field-search.svg');

        }

    }

}
input[type="submit"] {
    -webkit-appearance: none;
}

.input-container{
    position:relative;
    &.is-danger{
        @include mixins.box-shadow(inset 0 0 0 4px variables.$amaranth_red);
    }
}
.clear-input{
    position:absolute;
    z-index:10;
    right:20px;
    top:15px;
    cursor: pointer;
    width:20px;
    height:20px;

    &:before{
        content:'';
        width: 2px;
        position:absolute;
        height:20px;
        background: variables.$dark_midnight_blue;
        transform-origin:center;
        transform: rotate(45deg);
        top: 0;
        left: 9px;
    }

    &:after{
        content:'';
        width: 2px;
        position:absolute;
        height:20px;
        background: variables.$dark_midnight_blue;
        transform-origin:center;
        transform: rotate(-45deg);
        top: 0;
        left: 9px;
    }
}
