@use "../utils/mixins";
@use "../utils/variables";

.react-calendar {

    @include mixins.breakpoint() {
         
    }

    //  MODAL TYPE
    &.modal-type {

        @include mixins.breakpoint() {

            @include mixins.transform(scale(2));

            padding: 16px 32px;

            border: none;
            border-radius: 5px;

        }

        .react-calendar__navigation {

            @include mixins.breakpoint() {

                margin: 0;

            }

            .react-calendar__navigation__arrow {

                @include mixins.breakpoint() {

                    font-size: 24px;

                }

                &:focus {

                    @include mixins.breakpoint() {

                        background-color: transparent;

                    }

                }

                &:disabled {

                    @include mixins.breakpoint() {

                        background-color: variables.$white;
                        opacity: 0.25;

                    }

                }

                &.react-calendar__navigation__prev2-button, 
                &.react-calendar__navigation__next2-button {

                    @include mixins.breakpoint() {
                        display: none;
                    }

                }

            }

            .react-calendar__navigation__label {

                @include mixins.breakpoint() {

                    font-family: 'National', sans-serif;
                    font-feature-settings: "tnum";
                
                    pointer-events: none;

                }

            }

        }

        .react-calendar__month-view  {

            .react-calendar__month-view__weekdays {
                
                @include mixins.breakpoint() {

                    border-bottom: solid 1px rgba(variables.$black,0.05);

                    margin: 0 0 16px 0;

                }

                .react-calendar__month-view__weekdays__weekday {

                    @include mixins.breakpoint() {

                        font-family: 'National', sans-serif;
                    
                        font-weight: variables.$regular;

                    }

                    abbr {

                        @include mixins.breakpoint() {

                            text-decoration: none;

                        }

                    }

                }

            }

            .react-calendar__month-view__days {

                @include mixins.breakpoint() {
                }

                .react-calendar__tile {

                    @include mixins.breakpoint() {

                        color: variables.$black;

                        font-family: 'National', sans-serif;
                        font-feature-settings: "tnum";
                    
                        width: 32px;
                        height: 32px;

                        padding: 0;

                        line-height: 100%;

                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-content: center;

                    }

                    abbr {

                        @include mixins.breakpoint() {

                            display: block;

                            height: 32px;
                            width: 32px;

                            line-height: 32px;

                        }

                    }

                    &.react-calendar__month-view__days__day--neighboringMonth {

                        @include mixins.breakpoint() {
                            color: rgba(variables.$black, 0.5);
                        }

                    }

                    &.react-calendar__tile--active {

                        @include mixins.breakpoint() {

                            background-color: variables.$white;

                        }

                        abbr {

                            @include mixins.breakpoint() {

                                border-radius: 5px;
    
                                color: variables.$white;
                                background-color: variables.$dark_midnight_blue;
    
                                border-radius: 50%;
    
                            }

                        }

                    }

                    &:disabled {

                        @include mixins.breakpoint() {

                            background-color: variables.$white;
                            opacity: 0.25;

                        }

                    }

                }

            }

        }

    }

}