@use "mixins";

.grow {
    @include mixins.breakpoint() {
        flex-grow: 1;
    }
}

.dont-grow {
    @include mixins.breakpoint() {
        flex-grow: 0;
    }
}

.shrink {
    @include mixins.breakpoint() {
        flex-shrink: 1;
    }
}

.dont-shrink {
    @include mixins.breakpoint() {
        flex-shrink: 0;
    }
}