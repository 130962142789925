@use "../utils/mixins";
@use "../utils/variables";

footer {

    @include mixins.breakpoint() {

        margin: auto 0 0 0;

        flex: none;

    }
    .btn {
        max-width:255px;
    }

    .row {
        justify-content: space-around;
    }

    .footer_banner {

        @include mixins.breakpoint() {

            display: flex;
            flex-direction: row;
            justify-content: center;

        }

        img {

            @include mixins.breakpoint() {

                display: block;
                
                margin: 0;

                width: 100%;
                height: auto;

                max-width: 1024px;

            }

        }

    }

    .footer_copyright {

        .footer_list {

            @include mixins.breakpoint() {

                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                padding: 8px 0;

            }

            li {

                @include mixins.breakpoint() {

                    padding: 0 16px;

                    font-size: 14px;
                    line-height: 16px;

                    border-right: solid 1px variables.$another_blue;

                }

                &:last-child {

                    @include mixins.breakpoint() {
                        border-right: none;
                    }

                }

                button {

                    @include mixins.breakpoint() {

                        color: variables.$dark_midnight_blue;

                        font-family: 'National';

                        font-size: 14px;
                        line-height: 16px;

                        border: none;
                        background-color: transparent;

                        padding: 0;
                        margin: 0;

                    }

                }

            }

        }

    }

    &.gap__footer {

        .container {
            &.auto {
                @include mixins.breakpoint() {
                    overflow: hidden;
                }
            }
        }

        .footer-banner__holder {

            @include mixins.breakpoint() {

                max-width: 728px;
                height: 90px;

                overflow: hidden;

            }

        }

    }

}   