@use "../utils/mixins";

.row {

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: row;

    }

    &.h-centered {

        @include mixins.breakpoint() {

            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;

        }

    }

    &.v-centered {

        @include mixins.breakpoint() {

            align-items: center;

        }

    }
    &.centered{
        justify-content: center;
        align-items: center;
    }

}

.column {

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: column;

    }

}