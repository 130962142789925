@use "../utils/mixins";
@use "../utils/variables";

.golfer-profile_head {

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        margin: 0 0 20px 0;

    }

    .golfer-profile_identity {

        .golfer-profile_name {

            @include mixins.breakpoint() {

                color: variables.$dark_midnight_blue;

                font-family: 'National Book',sans-serif;

                font-size: 50px;
                line-height: 40px;

                margin: 0 0 8px 0;

                position: relative;

            }

        }

        .profile-search {

            @include mixins.breakpoint() {

                width: 45px;
                height: 45px;

                border-radius: 50%;

                display: inline-block;

                margin: 0 0 0 16px;

                background-repeat: no-repeat;
                background-attachment: scroll;
                background-position: center center;
                background-color: variables.$white;

                background-size: 24px 24px;

                background-image: url('../img/field-search.svg');

                position: absolute;

                right: -55px;

                cursor: pointer;

            }

        }

        .golfer-profile_ghin-container {

            .golfer-profile_ghin-label {

                @include mixins.breakpoint() {

                    color: variables.$cerulean_blue;

                    font-family: 'National Book',sans-serif;

                    font-size: 16px;

                }

            }

            .golfer-profile_ghin-number {

                @include mixins.breakpoint() {

                    color: variables.$dark_midnight_blue;

                    font-family: 'National Book',sans-serif;

                    font-size: 16px;
                    font-weight: variables.$bold;

                    font-feature-settings: "tnum";

                    margin: 0 0 0 4px;

                }

            }

        }

    }

    .golfer-profile_stats {

        .golfer-profile_handicap-container {

            @include mixins.breakpoint() {

                display: flex;
                flex-direction: row;

            }

            .golfer-profile_profile_handicap-index {

                @include mixins.breakpoint() {

                    color: variables.$dark_midnight_blue;

                    font-family: 'National Book',sans-serif;

                    font-size: 50px;
                    line-height: 40px;

                    font-feature-settings: "tnum";

                    margin: 0 8px 0 0;
                    position: relative;

                }

                .tooltip {

                    .info-modal-trigger {

                        @include mixins.breakpoint() {

                            color: variables.$dark_midnight_blue;

                            font-size: 12px;

                            width: 18px;
                            height: 18px;

                            font-weight: variables.$bold;

                            border-radius: 50%;
                            border: solid 2px variables.$dark_midnight_blue;

                            background-color: transparent;

                            display: block;

                            position: absolute;

                            padding: 0 !important;

                            left: -20px;
                            top: 3px;

                            cursor: pointer;

                        }

                    }
                    .tooltip-content {
                        border-radius: 3px;
                        display: inline-block;
                        font-size: 13px;
                        line-height: 13px;
                        opacity: 0;
                        padding: 8px 21px;
                        position: absolute;
                        top: -5px;
                        min-width: 360px;
                        right: calc(100% + 30px);
                        color: #222;
                        background-color: #fff;
                        pointer-events: none;
                        -webkit-transition: opacity 0.3s ease-out;
                        transition: opacity 0.3s ease-out;
                        visibility: hidden;
                        z-index: 999;

                        &.show {
                            opacity: 0.9;
                            visibility: visible;
                        }
                        &:after {
                            border-left-color: #fff;
                            border-left-style: solid;
                            border-left-width: 6px;
                            border-top: 5px solid transparent;
                            border-bottom: 5px solid transparent;
                            right: -6px;
                            top: 15px;
                            margin-top: -4px;
                            content: "";
                            width: 0;
                            height: 0;
                            position: absolute;
                        }
                    }

                }


            }

            .golfer-profile_handicap-label {

                @include mixins.breakpoint() {

                    color: variables.$dark_midnight_blue;

                    font-family: 'National',sans-serif;

                    font-size: 16px;
                    line-height: 16px;
                    font-weight: variables.$extra-bold;

                    text-transform: uppercase;

                    padding: 4px 0;

                }

                sup {

                    @include mixins.breakpoint() {

                        font-size: 9px;
                        vertical-align: super;

                    }

                }

            }

        }

        .golfer-profile_trend-container {

            .golfer-profile_trend-label {

                @include mixins.breakpoint() {

                    color: variables.$cerulean_blue;

                    font-family: 'National Book',sans-serif;

                    font-size: 16px;

                    text-transform: uppercase;

                }

            }

            .golfer-profile_trend-index {

                @include mixins.breakpoint() {

                    color: variables.$dark_midnight_blue;

                    font-family: 'National Book',sans-serif;

                    font-size: 16px;
                    font-weight: variables.$bold;

                    font-feature-settings: "tnum";

                    margin: 0 0 0 4px;

                }

            }

        }

        //  TYPE B
        &.type-b {

            @include mixins.breakpoint() {

                display: flex;
                flex-direction: column;
                align-items: center;

            }

            .type-b_title {

                @include mixins.breakpoint() {

                    color: variables.$dark_midnight_blue;

                    font-family: 'National',sans-serif;

                    font-size: 18px;
                    line-height: 18px;
                    font-weight: variables.$extra-bold;

                    text-transform: uppercase;

                    padding: 0 0 8px 0;

                }

                sup {

                    @include mixins.breakpoint() {

                        font-size: 9px;
                        vertical-align: super;

                    }

                }

            }

            .type-b_stats-container {

                @include mixins.breakpoint() {

                    display: flex;
                    flex-direction: row;

                }

                .type-b_cell {

                    @include mixins.breakpoint() {

                        display: flex;
                        flex-direction: row;

                        padding: 0 16px;

                    }

                    &:first-of-type {

                        @include mixins.breakpoint() {

                            border-right: solid 1px rgba(variables.$black, 0.15);

                        }

                    }

                    .type-b_index {

                        @include mixins.breakpoint() {

                            color: variables.$dark_midnight_blue;

                            font-family: 'National Book',sans-serif;

                            font-size: 50px;
                            line-height: 40px;

                            font-feature-settings: "tnum";

                            margin: 0 8px 0 0;

                        }

                    }

                    .type-b_micro-stats {

                        .top {

                            @include mixins.breakpoint() {

                                color: variables.$dark_midnight_blue;

                                font-family: 'National Book',sans-serif;

                                font-size: 16px;
                                font-weight: variables.$bold;

                                font-feature-settings: "tnum";

                            }

                        }

                        .bottom {

                            .type-b_label {

                                @include mixins.breakpoint() {

                                    color: variables.$cerulean_blue;

                                    font-family: 'National Book',sans-serif;

                                    font-size: 16px;

                                    text-transform: uppercase;

                                }

                            }

                            .type-b_data {

                                @include mixins.breakpoint() {

                                    color: variables.$dark_midnight_blue;

                                    font-family: 'National Book',sans-serif;

                                    font-size: 16px;
                                    font-weight: variables.$bold;

                                    font-feature-settings: "tnum";

                                    margin: 0 0 0 4px;

                                }

                            }

                        }

                    }

                }

            }

        }

    }

}

.golfer-profile_body {

    @include mixins.breakpoint() {

        padding: 64px 0 0 0;

    }

    .golfer-profile_actions {

        @include mixins.breakpoint() {

            display: flex;
            flex-direction: row;
            justify-content: center;

        }

        .action {

            @include mixins.breakpoint() {

                flex: none;

                width: 180px;
                height: 240px;

                border-radius: 3px;
                border: 1px solid variables.$midnight_blue;

                background-color: variables.$white;

                margin: 0 22px;
                padding: 32px 0 0 0;

                display: flex;
                flex-direction: column;

                align-items: center;

                text-decoration: none;

                transform-origin: center center;
                will-change: scale;

                -webkit-tap-highlight-color:transparent;

                @include mixins.user-select(none);

                @include mixins.transition(all 300ms ease-in-out 0ms);
                @include mixins.transform(scale(1.0));

            }

            &:hover {

                @include mixins.breakpoint() {
                    @include mixins.transform(scale(1.1));
                }

            }

            &:first-of-type {
                @include mixins.breakpoint() {
                    margin: 0 22px 0 0;
                }
            }

            &:last-of-type {
                @include mixins.breakpoint() {
                    margin: 0 0 0 22px;
                }
            }

            .action_icon {

                @include mixins.breakpoint() {

                    width: 72px;
                    height: 72px;

                    display: block;

                    margin: 0 0 24px 0;

                    background-repeat: no-repeat;
                    background-attachment: scroll;
                    background-position: center center;
                    background-size: cover;

                }

                &.golf {

                    @include mixins.breakpoint() {
                        background-image: url('../img/golf.svg');
                    }

                }

                &.pencil {

                    @include mixins.breakpoint() {
                        background-image: url('../img/pencil.svg');
                    }

                }

                &.user {

                    @include mixins.breakpoint() {
                        background-image: url('../img/user.svg');
                    }

                }

                &.lookup {
                    @include mixins.breakpoint() {
                        background-image: url('../img/search.svg');
                    }
                }

            }

            .action_name {

                @include mixins.breakpoint() {

                    color: variables.$dark_midnight_blue;

                    text-align: center;

                    font-size: 24px;
                    line-height: 28px;
                    font-weight: variables.$medium;

                }

            }

        }

    }

}
