@use "../utils/mixins";
@use "../utils/variables";

.details-container {

    @include mixins.breakpoint() {

        display: flex;
        flex-direction: row;
        align-items: center;

        margin: 24px 0 0 0;

    }

    .loading {

        @include mixins.breakpoint() {

            width: 120px;
            height: 40px;
            margin: auto;

            background-repeat: no-repeat;
            background-attachment: scroll;
            background-position: center center;
            background-size: contain;

            background-image: url('../img/loader.svg');

        }
        &.gray {
            background-image: url('../img/loader-on-white.svg');
            margin-bottom: 24px;
        }

    }

    .details_column {

        @include mixins.breakpoint() {

            flex: none;

            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

        }

        &:first-of-type {
            @include mixins.breakpoint() {
                width: calc(55% - 24px);
                margin: 0 24px 0 0;
            }
        }

        &:last-of-type {
            @include mixins.breakpoint() {
                width: calc(45% - 24px);
                margin: 0 0 0 24px;
            }
        }

        &.centered {
            justify-content: center;
            align-items: center;
        }

        .details_cell {

            @include mixins.breakpoint() {

                background-color: variables.$white;
                border-radius: 5px;
                border: 1px solid variables.$midnight_blue;
                margin-bottom: 16px;

                padding: 16px;

                display: flex;
                flex-direction: column;

            }

            &.disabled {
                pointer-events: none;
                background-color: variables.$lavender;
            }

            &:first-child {

                @include mixins.breakpoint() {
                    width: 100%;
                }

            }

            &:not(:first-child) {

                @include mixins.breakpoint() {
                    width: calc(50% - 8px);
                    height: 110px;
                }

                &:nth-child(odd) {

                    @include mixins.breakpoint() {
                        margin-left: 7.5px;
                    }

                }

                &:nth-child(even) {

                    @include mixins.breakpoint() {
                        margin-right: 7.5px;
                    }

                }

            }

            .details_label {

                @include mixins.breakpoint() {

                    color: variables.$another_blue;

                    font-size: 20px;
                    line-height: 1;
                    font-weight: variables.$regular;

                    text-transform: uppercase;

                    margin: 0 0 4px 0;

                }

            }

            .details_data {

                @include mixins.breakpoint() {

                    color: variables.$dark_midnight_blue;

                    font-size: 24px;
                    line-height: 1;
                    font-weight: variables.$medium;

                    font-feature-settings: "tnum";

                }

                &.pointer {

                    @include mixins.breakpoint() {

                        cursor: pointer;

                    }

                }

            }

            .details_data-sub {

                @include mixins.breakpoint() {

                    color: variables.$dark_midnight_blue;

                    font-size: 20px;
                    font-weight: variables.$medium;

                }

            }

        }

    }

}
