@use "../utils/mixins";
@use "../utils/variables";

.btn {

    @include mixins.breakpoint() {

        height: 52px;
        min-width: 240px;

        padding: 0 24px;

        border: none;

        font-family: 'National',sans-serif;
        font-feature-settings: "tnum";

        font-size: 16px;
        line-height: 52px;

        font-weight: variables.$bold;

        letter-spacing: 2px;

        text-transform: uppercase;
        text-align: center;
        text-decoration: none;

        display: block;

        border-radius: 26px;

        cursor: pointer;

        position:relative;
        z-index:5;

    }

    &.margin48 {
        @include mixins.breakpoint() {
            margin: 0 48px;
        }
    }

    &.margin56 {
        @include mixins.breakpoint() {
            margin: 0 56px;
        }
    }

    &.full {
        @include mixins.breakpoint() {
            width: 100%;
        }
    }

    &:focus {

        @include mixins.breakpoint() {

            outline: none;

        }

    }

    &.fill {

        &.red {

            @include mixins.breakpoint() {

                color: variables.$white;
                background-color: variables.$cardinal;

            }

        }

        &.blue {

            @include mixins.breakpoint() {

                color: variables.$white;
                background-color: variables.$dark_midnight_blue;

            }

        }

    }

    &.right{
        min-width: 100px;
        margin-left: auto;
        display: flex;
        align-items: center;
        font-family: 'National',sans-serif;
        font-feature-settings: "tnum";

        font-size: 20px;
        line-height: 32px;
        font-weight: variables.$bold;

        &::after {

            @include mixins.breakpoint() {

                content: '';
                display: block;
                width: 10px;
                height: 10px;

                border-top: solid 2px variables.$white;
                border-right: solid 2px variables.$white;

                @include mixins.transform(rotate(45deg));

                margin: 0 0 0 8px;

            }

        }
    }

    &.outline {

        &.blue {

            @include mixins.breakpoint() {

                background-color: variables.$white;
                color: variables.$dark_midnight_blue;
                @include mixins.box-shadow(inset 0 0 0 2px variables.$dark_midnight_blue);

            }

            &.transparent {

                @include mixins.breakpoint() {
                    background-color: transparent;
                }

            }

        }

    }

    &.top-margin50 {
        margin-top: 50px;
    }

    &:disabled {
    opacity:0.5;
}

}
